
















import { Component, Vue } from 'vue-property-decorator';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
am4core.useTheme(am4themes_animated);
import {
  ObjectiveNumber,
  ObjectiveNumberResponse,
} from '../../models/Objectives';
import { AxiosResponse } from 'axios';
@Component
export default class Numbers extends Vue {
  public $refs!: Vue['$refs'] & {
    chart: any;
  };
  public $mq: string;

  public loading: boolean = true;
  public chart: am4core.Container;

  public mounted() {
    this.initChart();

    this.$http
      .get<ObjectiveNumberResponse[]>(this.$apiRoutes.objectiveNumbers)
      .then((response: AxiosResponse<ObjectiveNumberResponse[]>) => {
        response.data.forEach((item: ObjectiveNumberResponse) => {
          this.addChartChild({
            title: item.title,
            description: item.description,
            target: item.target,
            currentValue: item.current_value,
          });
        });
      })
      .finally(() => (this.loading = false));
  }

  private initChart() {
    const chart = am4core.create(this.$refs.chart, am4core.Container);
    chart.width = am4core.percent(100);
    chart.height = am4core.percent(100);
    chart.layout = ['xs', 'sm'].includes(this.$mq) ? 'vertical' : 'horizontal';
    this.chart = chart;
  }

  private addChartChild(numbers: ObjectiveNumber) {
    const child = this.chart.createChild(am4charts.GaugeChart);
    child.startAngle = 90;
    child.endAngle = 450;
    child.innerRadius = -5;
    child.nonScaling = true;

    const axis: am4charts.ValueAxis<am4charts.AxisRendererCircular> = child.xAxes.push(
      new am4charts.ValueAxis<am4charts.AxisRendererCircular>(),
    );
    axis.numberFormatter.numberFormat = '#,###.00';
    axis.min = 0;
    axis.max = numbers.target;
    axis.strictMinMax = true;
    axis.renderer.minGridDistance = numbers.target;
    axis.renderer.labels.template.disabled = true;

    const range = axis.axisRanges.create();
    range.value = 0;
    range.endValue = numbers.currentValue;
    range.axisFill.fillOpacity = 1;
    range.axisFill.fill = am4core.color('#F03511');
    range.axisFill.zIndex = -1;

    const label: am4core.Label = child.radarContainer.createChild(
      am4core.Label,
    );
    label.isMeasured = false;
    label.fontSize = 35;
    label.x = am4core.percent(50);
    label.y = am4core.percent(50);
    label.horizontalCenter = 'middle';
    label.verticalCenter = 'middle';
    label.text = new Intl.NumberFormat().format(numbers.currentValue);
    label.dy = -10;

    const labelDescription: am4core.Label = child.radarContainer.createChild(
      am4core.Label,
    );
    labelDescription.isMeasured = false;
    labelDescription.fontSize = 25;
    labelDescription.x = am4core.percent(50);
    labelDescription.y = am4core.percent(50);
    labelDescription.dy = 25;
    labelDescription.horizontalCenter = 'middle';
    labelDescription.verticalCenter = 'middle';
    labelDescription.text = numbers.title;
  }
}
